.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
}

.home h1 {
  font-size: 5vw;
  margin-bottom: 1rem;
}

.home h2 {
  font-size: 2.5vw;
  line-break: anywhere;
  word-wrap: break-word;
  max-width: 100%;
  margin-bottom: 1rem;
}

.home h3 {
  font-size: 2.5vw;
  text-align: center;
  margin-top: 1rem;
}

.logo-video {
  width: 20%;
  max-width: 300px;
  border: 5px solid white;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .home {
    padding: 1rem;
  }

  .home h1 {
    font-size: 8vw;
  }

  .home h2,
  .home h3 {
    font-size: 4vw;
  }

  .home img {
    width: 30%;
  }
}

@media screen and (max-width: 480px) {
  .home h1 {
    font-size: 10vw;
  }

  .home h2,
  .home h3 {
    font-size: 5vw;
  }

  .logo-video {
    width: 50%;
  }
}
