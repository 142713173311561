/* .navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
}

.navbar-logo .main-logo {
  height: 60px;
  width: auto;
}

.navbar-social {
  display: flex;
  gap: 15px;
}

.social-logo {
  height: 60px;
  width: auto;
  transition: transform 0.3s ease;
}

.social-logo:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .navbar-logo {
    margin-bottom: 10px;
  }

  .navbar-logo .main-logo {
    height: 45px;
  }

  .navbar-social {
    gap: 10px;
  }

  .social-logo {
    height: 35px;
  }
}

@media screen and (max-width: 480px) {
  .navbar-logo .main-logo {
    height: 35px;
  }

  .social-logo {
    height: 40px;
  }
} */

.container {
  width: 100%;
  padding: 10px 0 0 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.left-side .main-logo {
  height: 60px;
  width: auto;
}

.right-side {
  display: flex;
  gap: 15px; /* Space between social logos */
}

.social-logo {
  height: 60px;
  width: auto;
  transition: transform 0.3s ease;
}

.social-logo:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 20px; /* Add margin on top for mobile */
  }

  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .left-side {
    margin-bottom: 20px; /* Space between main logo and social logos on mobile */
  }

  .left-side .main-logo {
    height: 45px;
  }

  .right-side {
    gap: 20px; /* Adjust space between social logos on mobile */
  }

  .social-logo {
    height: 35px;
  }
}

@media screen and (max-width: 480px) {
  .left-side .main-logo {
    height: 40px;
  }

  .right-side {
    gap: 10px; /* Further reduce space on smaller screens */
  }

  .social-logo {
    height: 40px;
  }
}
