@font-face {
  font-family: "Kocheng";
  src: url("./fonts/kocheng.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Kocheng", sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  letter-spacing: 1px;
  color: white;
}

.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Kocheng", sans-serif;
  background-image: url("./assets/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 768px) {
  body {
    -webkit-text-stroke-width: 0.5px;
    letter-spacing: 0.5px;
  }
}
